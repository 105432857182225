@keyframes fader {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 0.85;
  }

  13% {
    opacity: 0.85;
  }

  15% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

img.bg {
  /* Set rules to fill background */
  min-height: 100%;
  /*min-width: 1024px; */

  /* Set up proportionate scaling */
  height: 100%;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  opacity: 0;
  overflow-y: auto;
  z-index: -50;

  animation: fader 70s linear infinite;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

img.bg:nth-child(1) {
  animation-delay: 0s;
}

img.bg:nth-child(2) {
  animation-delay: 10s;
}

img.bg:nth-child(3) {
  animation-delay: 20s;
}

img.bg:nth-child(4) {
  animation-delay: 30s;
}

img.bg:nth-child(5) {
  animation-delay: 40s;
}

img.bg:nth-child(6) {
  animation-delay: 50s;
}

img.bg:nth-child(7) {
  animation-delay: 60s;
}

.Story {
  font-size: 40px;
  color: #fff;
  text-align: center;
  text-shadow: 0 0 1rem magenta, 0 0 2rem white;
  position: absolute;
  top: 15%;
  margin: 0 15%;
  width: 70%;
  left: 0;
}

.github {
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30px;
  z-index: 100;
}

.location {
  font-size: 16px;
  bottom: 0;
  padding: 0;
  position: absolute;
  z-index: -15;
  margin-bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

textarea {
  padding: 5px;
  top: 65%;
  height: 15%;
  width: 60%;
  margin: 0;
  left: calc(20% - 5px);
  font-size: 20px;
  resize: none;
  position: absolute;
  background:
    radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8));

  z-index: 5;
  border-radius: 10px;
}

@media (max-width:800px) and (min-width:0px) {
  .Story {
    font-size: 25px;
  }

  textarea {
    font-size: 15px;
  }

  .github {
    margin: 1%;
    width: 5%;
  }
}